import React from 'react';

import SEO from '../components/content/seo';
import Layout from '../containers/layout';
import Copyright from '../components/pages/copyright';

const seo = {
  title: 'Copyright Policy',
  description: 'Copyright Policy for www.livemenu.io.',
  keywords: ['copyright', 'policy'],
};

const CopyrightPage = props => {
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <Copyright />
    </Layout>
  );
};

export default CopyrightPage;
